<template>
  <b-overlay :show="buzy">
    <div class="d-flex align-items-center">
      <template v-if="localModel || editing">
        +7
      </template>
      <edit-input
        style="width: fit-content"
        :model="localModel"
        :toggle-only-by-icon="false"
        :mask="mask"
        :length="15"
        is-phone
        :readonly="readonly"
        @input="onInput"
        @edit="onEdit"
        @blur="onBlur"
      >
        <slot />
      </edit-input>
    </div>
  </b-overlay>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import { BOverlay } from 'bootstrap-vue';
import EditInput from '../editInput/EditInput.vue';

export default {
  name: 'FieldPhone',
  components: { EditInput, BOverlay },
  props: {
    model: {
      type: String,
      default: '',
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const buzy = ref(false);
    const { model, isPhone } = toRefs(props);

    const editing = ref(false);
    const mask = ref(['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]);
    const localModel = ref();

    const fillLocalModel = () => {
      if (isPhone.value && model.value) localModel.value = model.value.slice(1);
      else localModel.value = model.value;
    };
    fillLocalModel();

    const onInput = (value) => {
      if (!value) emit('input', value);
      else emit('input', `7${value}`);
      buzy.value = true;
    };

    const onEdit = () => {
      editing.value = true;
    };
    const onBlur = () => {
      editing.value = false;
    };

    return {
      localModel,
      onInput,
      mask,
      buzy,
      editing,
      onEdit,
      onBlur,
    };
  },
};
</script>
