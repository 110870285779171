<template>
  <b-card class="h-100 mb-0 employee-card">
    <div
      class="position-absolute mr-1 mt-1"
      style="top: 0; right: 0"
    >
      <b-dropdown
        v-if="$store.getters['user/permission']('users:update')"
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        :right="isMobile"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="24"
          />
        </template>
        <b-dropdown-item :to="`/settings/users/${employee.id}`">
          {{ t('employees.actions.rightsSettings') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-media
      :class="{'mt-2' : isMobile}"
    >
      <template #aside>
        <div class="d-flex flex-column justify-content-center">
          <b-avatar
            class="badge-light-success"
            variant="secondary"
            :src="employee.avatar"
            size="104px"
            rounded
          />
          <small class="mt-50">{{ formattedTime() }}</small>
        </div>
      </template>

      <h4>{{ employee.firstname }} {{ employee.surname }} {{ employee.patronymic }}</h4>
      <p>{{ employee.description }}</p>
      <tags
        :entity="employee"
        entity-name="employee"
        @input="$emit('needToUpdate')"
      />
    </b-media>
    <div class="d-flex flex-column mt-2 employee-card_props">
      <div class="d-flex align-items-center">
        <feather-icon
          icon="PhoneIcon"
          class="mr-75 flex-shrink-0"
        />
        <h6
          class="mb-0 mr-1 overflow-hidden"
          style="flex: 0 0 86px"
        >
          {{ t('employees.field.phone.value') }}
        </h6>
        <h6
          class="mb-0 flex-fill font-weight-normal"
        >
          <mask-field
            :key="lastUpdated"
            is-phone
            :model="employee.phone"
            :readonly="!$store.getters['user/permission']('employees:update')"
            @input="updateField('phone', $event)"
          >
            {{ employee.phone || t('employees.field.phone.empty') }}
          </mask-field>
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <feather-icon
          icon="PhoneIcon"
          class="mr-75 flex-shrink-0"
        />
        <h6
          class="mb-0 mr-1 overflow-hidden"
          style="flex: 0 0 86px"
        >
          {{ t('employees.field.personal_phone.value') }}
        </h6>
        <h6
          class="mb-0 flex-fill font-weight-normal"
        >
          <mask-field
            :key="lastUpdated"
            :model="employee.personal_phone"
            is-phone
            :readonly="!$store.getters['user/permission']('employees:update')"
            @input="updateField('personal_phone', $event)"
          >
            {{ employee.personal_phone || t('employees.field.personal_phone.empty') }}
          </mask-field>
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <feather-icon
          icon="MailIcon"
          class="mr-75 flex-shrink-0"
        />
        <h6
          class="mb-0 mr-1 overflow-hidden"
          style="flex: 0 0 86px"
        >
          {{ t('employees.field.email') }}
        </h6>
        <h6
          class="mb-0 flex-fill font-weight-normal"
          style="width: fit-content"
        >
          {{ employee.email }}
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <feather-icon
          icon="ClockIcon"
          class="mr-75 flex-shrink-0"
        />
        <h6
          class="mb-0 mr-1 overflow-hidden"
          style="flex: 0 0 86px"
        >
          {{ t('employees.field.workSchedule') }}
        </h6>
        <h6
          class="mb-0 flex-fill font-weight-normal"
        >
          <employee-schedule
            style="width: fit-content"
            :employee="employee"
            :readonly="!$store.getters['user/permission']('employees:update')"
            @input="$emit('needToUpdate')"
          >
            {{ t('employees.schedule.open') }}
          </employee-schedule>
        </h6>
      </div>
    </div>
    <div class="mt-3 d-flex flex-wrap gap-x-7 gap-y-2">
      <!-- <div class="d-flex align-items-center">
        <b-avatar
          variant="light-primary"
          rounded
          size="38px"
        >
          <feather-icon
            icon="TrendingUpIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h4 class="mb-0">
            {{ monthProfit }}
          </h4>
          <small>{{ t('employees.field.monthProfit') }}</small>
        </div>
      </div> -->
      <!-- <div class="d-flex align-items-center">
        <b-avatar
          variant="light-success"
          rounded
          size="38px"
        >
          <feather-icon
            icon="DollarSignIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h4 class="mb-0">
            {{ totalProfit }}
          </h4>
          <small>{{ t('employees.field.totalProfit') }}</small>
        </div>
      </div> -->
      <!-- <div class="d-flex align-items-center">
        <b-avatar
          variant="light-warning"
          rounded
          size="38px"
        >
          <feather-icon
            icon="StarIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h4 class="mb-0">
            <employee-rating :rating="employee.rating" />
          </h4>
          <small>{{ t('employees.field.rating') }}</small>
        </div>
      </div> -->
      <div class="d-flex align-items-center justify-content-end flex-fill">
        <b-avatar
          :variant="employee.is_active ? 'light-success' : 'secondary'"
          rounded
          size="38px"
        >
          <feather-icon
            v-if="employee.is_active"
            icon="UserCheckIcon"
            size="18"
          />
          <feather-icon
            v-else
            icon="UserXIcon"
            size="18"
          />
        </b-avatar>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { formatPrice } from '@/utils/price';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
// import EmployeeRating from '@/components/employeeRating/EmployeeRating.vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { getTimeDifference } from "@/utils/time";
import EmployeeSchedule from '../employeeSchedule/EmployeeSchedule.vue';
import MaskField from '../maskField/MaskField.vue';
import Tags from '../tags/Tags.vue';

export default {
  name: 'EmployeeCard',
  components: {
    BCard,
    BMedia,
    BAvatar,
    // EmployeeRating,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    EmployeeSchedule,
    MaskField,
    Tags,
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { successToast, dangerToast } = useToast();
    const { t } = useI18n();
    const { employee } = toRefs(props);
    const isMobile = ref(false);

    isMobile.value = store.state.app.isMobile;

    // Stats
    const monthProfit = computed(() => formatPrice(
      employee.value.month_profit,
      // TODO Currency
      {
        rate: 1,
        iso: 'RUB',
      },
      0,
      'en',
      false,
      'code',
    ));
    const totalProfit = computed(() => formatPrice(
      employee.value.all_profit,
      // TODO Currency
      {
        rate: 1,
        iso: 'RUB',
      },
      0,
      'en',
      false,
      'code',
    ));

    const lastUpdated = ref(Date.now());
    const updateField = async (field, value) => {
      const model = employee.value;
      model[field] = value;
      const { result } = await store.dispatch('employees/updateEmployee', {
        employeeId: employee.value.id,
        model: {
            email: model.email,
            firstname: model.firstname,
            is_active: model.is_active,
            is_serviceman: model.is_serviceman,
            phone: model.phone,
            role: model.role.name,
            surname: model.surname,
            personal_phone: model.personal_phone
          },
      });

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
        employee.value[field] = value;
        return;
      }
      dangerToast(
        t('errors.something-went-wrong.text'),
        t('errors.message.updateFailedMessage'),
      );
    };

    const formattedTime = () => {
      if (employee.value.last_geo_location && employee.value.last_geo_location.time) {
        return getTimeDifference(employee.value?.last_geo_location?.time);
      }
      return null;
    };

    watch(employee, () => {
      lastUpdated.value = Date.now();
    }, { deep: true });

    return {
      t,

      monthProfit,
      totalProfit,
      updateField,
      lastUpdated,
      formattedTime,
      isMobile,
    };
  },
};
</script>

<style lang="sass">
.employee-card
  &_props
    & > *
      min-height: 30px
      & > svg
        @media (max-width: 767px)
          display: none
</style>
