<template>
  <div>
    <div
      v-if="$store.getters['user/permission']('employees:update')"
      v-b-modal.schedule-modal
    >
      <slot v-if="!stringSchedule" />
      <template v-else>
        {{ stringSchedule }}
      </template>
    </div>

    <div v-else>
      <slot v-if="!stringSchedule" />
      <template v-else>
        {{ stringSchedule }}
      </template>
    </div>

    <b-modal
      id="schedule-modal"
      size="lg"
      footer-class="justify-content-start"
      @ok="submitModel"
      @cancel="resetModel"
    >
      <div class="my-2 schedule-modal">
        <div class="mb-1 d-flex flex-column align-items-start flex-lg-row gap-2">
          <BButton
            variant="link"
            class="p-0"
            @click="selectByTemplate(1)"
          >
            Будние
          </BButton>
          <BButton
            variant="link"
            class="p-0"
            @click="selectByTemplate(2)"
          >
            Выходные
          </BButton>
          <BButton
            variant="link"
            class="p-0"
            @click="selectByTemplate(3)"
          >
            Ежедневно
          </BButton>
        </div>
        <div>
          <employee-schedule-select
            v-for="(item, index) in model"
            :key="index"
            class="mb-1"
            :index="index"
            :value="model[index]"
            :removable="model.length !== 1"
            :selected-days="selectedDays"
            @input="updateModelByDay"
            @remove="remove"
          />
        </div>

        <div
          v-if="model.length < 7 && selectedDays.length !== 7"
          class="text-primary font-weight-bold"
          @click="add"
        >
          {{ t('employees.schedule.add') }}
        </div>
      </div>

      <template #modal-ok>
        {{ t('settings.save') }}
      </template>
      <template #modal-cancel>
        {{ t('settings.cancel') }}
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center gap-2 mt-1">
          <feather-icon
            icon="ClockIcon"
            size="24"
          />
          {{ t('employees.schedule.dialog.title') }}
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import EmployeeScheduleSelect from './EmployeeScheduleSelect.vue';
import data from './data';
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue';

export default {
  name: 'EmployeeSchedule',
  components: {
    BModal,
    BButton,
    EmployeeScheduleSelect,
    FeatherIcon,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { successToast, dangerToast } = useToast();
    const { employee } = toRefs(props);

    const model = ref(null);

    const updateModel = (value) => {
      model.value = value;
    };
    const updateModelByDay = ({ index, value }) => {
      model.value[index] = value;
    };

    const selectByTemplate = (templateId) => {
      let template = [{ ...data.defaults[templateId] }];

      if (templateId === 2) {
        const templateFilteredModel = [];
        model.value.forEach((item) => {
          const templateFilteredModelDays = item.days.filter((i) => !['6', '7'].includes(i));
          if (templateFilteredModelDays.length) {
            templateFilteredModel.push({
              days: templateFilteredModelDays,
              time_from: item.time_from,
              time_to: item.time_to,
            });
          }
        });
        template = [...templateFilteredModel, ...template];
      }

      updateModel(template);
    };

    const add = () => {
      if (model.value.length === 7) return;
      model.value.push({ ...data.defaults[0] });
    };
    const remove = (index) => {
      if (model.value.length > 0) model.value.splice(index, 1);
    };
    const selectedDays = computed(() => {
      const selected = [];
      model.value.forEach((item) => {
        item.days.forEach((day) => {
          if (!selected.includes(day)) selected.push(day);
        });
      });
      return selected;
    });

    const serverScheduleFormatted = computed(() => {
      const result = [];
      employee.value.schedule_work.forEach((item) => {
        let serverTimeFrom = item.time_from;
        if (serverTimeFrom.length === 8) serverTimeFrom = serverTimeFrom.slice(0, 5);
        let serverTimeTo = item.time_to;
        if (serverTimeTo.length === 8) serverTimeTo = serverTimeTo.slice(0, 5);
        result.push({
          days: item.days,
          time_from: serverTimeFrom,
          time_to: serverTimeTo,
        });
      });
      return result;
    });

    const stringSchedule = computed(() => {
      const items = [];
      serverScheduleFormatted.value.forEach((modelItem) => {
        let days;
        if (modelItem.days.length === 7) days = t('days.daily');
        else if (
          modelItem.days.length === 5
          && !modelItem.days.includes('6')
          && !modelItem.days.includes('7')
        ) days = t('days.weekdays');
        else if (
          modelItem.days.length === 2
          && modelItem.days.includes('6')
          && modelItem.days.includes('7')
        ) days = t('days.weekend');
        else days = modelItem.days.map((i) => t(`days.short.${i}`));

        const time = `${modelItem.time_from} - ${modelItem.time_to}`;
        const string = `${days} ${time}`;
        items.push(string);
      });
      return items.join('; ');
    });

    const loadData = () => {
      model.value = serverScheduleFormatted.value.length
        ? Object.assign([], serverScheduleFormatted.value)
        : [{ ...data.defaults[1] }];
    };
    watch(employee, loadData, { deep: true, immediate: true });

    const submitModel = async () => {
      let validated = true;
      model.value.forEach((item) => {
        if (item.days.length === 0) {
          validated = false;
          dangerToast(
            t('errors.message.updateFailedMessage'),
            t('errors.message.scheduleEmptyDays'),
          );
        }
      });

      if (validated) {
        // TODO: время сохраняется без конвертации в UTC
        const { result } = await store.dispatch('employees/updateEmployeeSchedule', { employeeId: employee.value.id, model: model.value });
        if (result) {
          emit('input');
          successToast(
            t('settings.messageUpdated.title'),
            t('settings.messageUpdated.text'),
          );
        } else {
          dangerToast(
            t('settings.updateFailedMessage.title'),
            t('settings.updateFailedMessage.text'),
          );
        }
      } else loadData();
    };

    const resetModel = () => {
      loadData();
    };

    return {
      t,

      model,
      updateModelByDay,
      selectByTemplate,
      add,
      remove,
      selectedDays,
      serverScheduleFormatted,
      stringSchedule,

      submitModel,
      resetModel,
    };
  },
};
</script>

<style lang="sass">
#schedule-modal
  & header
      background-color: transparent !important
.schedule-modal
  padding-bottom: 144px
</style>
