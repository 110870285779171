<template>
  <div>
    <!-- <b-overlay :show="isFetchingData"> -->
    <template v-if="employeeData">
      <b-row class="mb-2">
        <b-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          class="mb-2 mb-lg-0"
        >
          <employee-card
            :employee.sync="employeeData"
            @needToUpdate="onNeedToUpdate"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <!-- <actions-card
            :employee="employeeData"
            :actions="employeeData.recent_actions"
          /> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card>
            <h4>
              Заявки
            </h4>
            <requests-table
              :employee-data="employeeData"
              :by-employee-id="employeeData.id"
              class="employee-reqeusts-list"
              map-view
              datepicker
              no-cache
            />
            <!--  -->
          </b-card>
        </b-col>
      </b-row>
    </template>
    <!-- </b-overlay> -->
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import {
  // BOverlay,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue';
import { useStore } from '@/hooks/useStore';
import EmployeeCard from '@/components/employeeCard/EmployeeCard.vue';
import RequestsTable from '@/components/entityTables/RequestsTable.vue';
// import ActionsCard from '@/components/actionsCard/ActionsCard.vue';
// import EmployeesRequestsMap from '@/components/employeesRequestsMap/EmployeesRequestsMap.vue';

export default {
  name: 'EmployeeView',
  components: {
    // BOverlay,
    BRow,
    BCol,
    BCard,
    EmployeeCard,
    RequestsTable,
  },
  props: {
    employeeId: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const { employeeId } = toRefs(props);
    const employeeData = ref(null);

    const isFetchingData = ref(false);
    const getEmployee = async () => {
      if (isFetchingData.value) return;

      isFetchingData.value = true;
      const { result, data } = await store.dispatch('employees/getEmployee', employeeId.value);
      isFetchingData.value = false;
      if (result) {
        employeeData.value = data;
      }
    };

    (async () => {
      await getEmployee();
    })();

    const onNeedToUpdate = async () => {
      await getEmployee();
    };

    return {
      employeeData,
      isFetchingData,
      onNeedToUpdate,
    };
  },
};
</script>

<style lang="sass" scoped>
.employee-reqeusts-list
  margin: 0 -1.5rem -1.5rem -1.5rem
</style>
