<template>
  <div class="d-flex align-items-center employee-schedule-select">
    <div
      class="d-flex align-items-start align-items-lg-center flex-column
    flex-lg-row employee-schedule-select-container"
    >
      <div class="mr-lg-2 mb-1 mb-lg-0 employee-schedule-select_days">
        <v-select
          :id="`week-${index}`"
          :value="model.days"
          :options="[...days, ...model.days].sort()"
          multiple
          :searchable="false"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="daysInput"
        >
          <template
            slot="selected-option"
            slot-scope="option"
          >
            {{ t(`days.short.${option.label}`) }}
          </template>
          <template v-slot:option="option">
            {{ t(`days.short.${option.label}`) }}
          </template>
        </v-select>
      </div>
      <div
        class="d-flex align-items-center employee-schedule-select_time-container"
      >
        <v-select
          :id="`start-${index}`"
          ref="time_from"
          v-model="model.time_from"
          class="employee-schedule-select_time"
          :clearable="false"
          :options="timeOptions"
          :autoscroll="false"
          @search:focus="openedTime('from')"
        />
        <div class="mx-50">
          -
        </div>
        <v-select
          :id="`end-${index}`"
          ref="time_to"
          v-model="model.time_to"
          class="employee-schedule-select_time"
          :clearable="false"
          :options="timeOptions"
          :autoscroll="false"
          @search:focus="openedTime('to')"
        />
      </div>
    </div>
    <div
      class="d-flex"
    >
      <BCard
        v-if="removable"
        class="mb-0 ml-1 ml-lg-2 employee-schedule-select_close"
        no-body
        role="button"
        @click="remove"
      >
        <feather-icon
          icon="XIcon"
        />
      </BCard>
    </div>
  </div>
</template>

<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import { BCard } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useI18n } from '@/hooks/useI18n';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import data from './data';

export default {
  name: 'EmployeeScheduleSelect',
  components: {
    vSelect,
    BCard,
    FeatherIcon,
  },
  props: {
    index: {
      type: [Number],
      default: 0,
    },
    value: {
      type: Object,
      default: () => ({ ...data.defaults[0] }),
    },
    selectedDays: {
      type: Array,
      default: () => ([]),
    },
    removable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { index, value, selectedDays } = toRefs(props);
    const status = ref(false);

    const model = ref(value.value);
    const days = computed(() => {
      const dataDays = [...data.days];
      return dataDays.filter((x) => !selectedDays.value.includes(x));
    });
    const timeOptions = ref(data.timeOptions);

    const remove = () => {
      emit('remove', index.value);
    };
    const input = () => {
      emit('input', { index: index.value, value: model.value });
    };
    const daysInput = (value) => {
      const valueSorted = value.sort((a, b) => ((a > b) ? 1 : ((b > a) ? -1 : 0)));
      model.value.days = valueSorted;
    };
    watch(model, input, { deep: true, immediate: true });
    watch(value, () => {
      model.value = value.value;
    }, { deep: true });

    return {
      t,

      status,
      days,
      timeOptions,
      model,

      remove,
      daysInput,
    };
  },
  methods: {
    openedTime(element) {
      this.$nextTick(() => {
        const active = this.$refs[`time_${element}`].$el.querySelector('.vs__dropdown-menu .vs__dropdown-option--selected');
        if (active) {
          active.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
        }
      });
    },
  },
};
</script>

<style lang="sass">
.employee-schedule-select
  & .vs__dropdown-menu
    max-height: 340px !important
  &-container
    @media (max-width: 575px)
      width: 100%
  &_days
    width: 100%
    & .vs__search, & .vs__actions
      padding: 0
      margin: 0
      max-width: 0
    @media (min-width: 992px)
      max-width: 416px
    @media (min-width: 768px)
      width: 409px
    @media (min-width: 576px)
      width: 416px
  &_time-container
    @media (max-width: 991px)
      width: 100%
  &_time
    width: 122px
    cursor: pointer !important
    & .vs__dropdown-toggle, & .vs__search
      cursor: pointer !important
    @media (max-width: 991px)
      width: 109px
      width: 50%
    & .vs__dropdown-toggle
      height: 38px
  &_close
    padding: 11px
    box-shadow: unset
    border: 1px solid #d8d6de
</style>
